/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback, useState, useEffect } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Layout, Content, Section } from "maker-ui";
import { eventCallbackTriggers } from "../templates/wppage";
import Seo from "../components/seo";
import options from "../mark-ui/options";
import theme from "../mark-ui/theme";

const RecurringGeneralDonationHIMobile = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "General Donation Recurring HI, USA";
    return `<gatsby_donation donationId = ${id} btntext = "${titletext}" btnBg = #333333 colortext = black colorprimary = #333333 colorsecondary = #333333 colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation Recurring - HI, USA" enablesigninoption = 0/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("General_Donation_Recurring_-_HI,_USA", event),
    []
  );

  const [isMobile, setIsMobile] = useState(false);

  const windowSizeTracker = () => {
    return window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    windowSizeTracker();
  }, []);

  return (
    <>
      <Layout theme={theme} options={options}>
        <Seo title="Heartfulness Donations - HI USA Recurring Mobile" />

        <Content>
          <Section>
            <Box sx={{ paddingBottom: isMobile ? "0px" : "5px" }}>
              <h2
                sx={{
                  fontWeight: 450,
                  fontSize: "25px",
                  textAlign: "center!important",
                  paddingBottom: "10px",
                  color: "rgb(51, 51, 51)",
                }}
              >
                Heartfulness USA Donations – Recurring
              </h2>
              <PageContent
                eventCallback={eventCallback}
                pageContent={pageContent(25, "Donate Now")}
              />
            </Box>
            <Grid
              columns={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: isMobile ? "0px 10px" : "0px 65px",
              }}
            >
              <Box>
                <h1
                  sx={{
                    marginTop: "15px",
                    fontWeight: "600",
                    lineHeight: "1.2",
                    fontSize: "1.5em",
                    textAlign: "center!important",
                  }}
                >
                  General Donation to HI – Recurring, USA
                </h1>
                <p
                  sx={{
                    fontWeight: "400",
                    lineHeight: "1.5",
                    fontSize: "18px",
                  }}
                >
                  Donations of any size make it possible for us to change lives
                  by teaching Heartfulness techniques. Our dedicated volunteers,
                  many of whom are professionals, donate their time and talents
                  and cover their own expenses. Your donation will support our
                  programs in schools, universities, colleges, corporations,
                  hospitals, and communities worldwide.
                  <p
                    sx={{
                      fontWeight: "400",
                      lineHeight: "1.5",
                      fontSize: "18px",
                    }}
                  >
                    All donations made are voluntary and are suggested to be
                    made to the general fund of Heartfulness Institute. General
                    funds are used for projects in areas of COVID 19 CARE,
                    Environment, Animal shelter, Green initiatives, Positive
                    lifestyle, and Sustainability initiatives, among others.
                  </p>
                </p>
              </Box>
              <Box>
                <div
                  sx={{
                    margin: "auto",
                    padding: "25px 15px",
                    boxShadow: "0 2px 4px 0 rgba(170,170,170,0.5)",
                    border: "solid 1px rgba(194,198,206,0.5)",
                  }}
                >
                  <p>
                    For matching double donation from your organisation, please
                    send email request to
                    <a
                      sx={{
                        wordBreak: "break-word",
                        marginTop: "12px",
                        marginLeft: "5px",
                      }}
                      href="mailto:us.treasurer@heartfulnessinstitute.org?Subject=Matching Double Donation (https://donations.heartfulness.org/heartfulness-institute-donation-usa/)"
                      target="_top"
                      rel="noopener noreferrer"
                    >
                      us.treasurer@heartfulnessinstitute.org
                    </a>
                  </p>
                </div>
              </Box>
              <Box>
                <div
                  className="row"
                  sx={{ alignItems: "center", justifyContent: "center" }}
                >
                  <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 text-center">
                    <PageContent
                      eventCallback={eventCallback}
                      pageContent={pageContent(25, "Donate Now")}
                    />
                  </div>
                </div>
              </Box>
            </Grid>
          </Section>
        </Content>
      </Layout>
    </>
  );
};

export default RecurringGeneralDonationHIMobile;
